<template>
  <div class="forgetWin">
    <div class="logo"><img src="@/assets/img/logo1.png" /></div>
    <div class="header">
      {{ lang_data == 1 ? "账号安全" : "Account security" }}
    </div>
    <div class="mcontent">
      <div class="til">
        {{ lang_data == 1 ? "找回密码" : "Retrieve password" }}
      </div>
      <div v-if="step == 1">
        <el-form
          ref="formvalue"
          @submit.native.prevent
          :model="formvalue"
          :rules="Rules2"
        >
          <div class="step">
            <div class="step1">
              <a class="act">{{
                lang_data == 1 ? "验证身份" : "Authenticate"
              }}</a
              ><a>{{
                lang_data == 1 ? "重置密码" : "To reset your password"
              }}</a>
            </div>
            <div class="step2"><a class="act">&nbsp;</a><a>&nbsp;</a></div>
          </div>

          <el-form-item label="" prop="phone" class="mt20">
            <el-radio-group v-model="radio">
              <el-radio :label="1">{{
                lang_data == 1 ? "手机号" : "Mobile"
              }}</el-radio>
              <el-radio :label="2">{{
                lang_data == 1 ? "邮箱" : "Email"
              }}</el-radio>
            </el-radio-group>
            <el-input
              v-model="formvalue.phone"
              size="small"
              type="text"
              style="width: 100%"
              :placeholder="
                lang_data == 1 ? '请输入手机号/邮箱' : 'Please enter'
              "
            />
          </el-form-item>

          <el-form-item label="" prop="pwdCode">
            <el-input
              v-model="formvalue.pwdCode"
              size="small"
              type="text"
              style="width: 220px"
              :placeholder="lang_data == 1 ? '请输入验证码' : 'Please enter'"
            />
            <el-button
              type="button"
              id="getCode"
              :disabled="codeIndex != 60"
              size="small"
              style="width: 132px"
              class="ml10"
              @click="getPwdCode"
              >{{ codeName }}</el-button
            >
          </el-form-item>
          <el-form-item label="">
            <el-button
              @click="checkChangePwdCode('formvalue')"
              style="width: 100%"
              size="small"
              type="primary"
              round
            >
              {{ lang_data == 1 ? "下一步" : "The next step" }}
            </el-button>
          </el-form-item>
        </el-form>
        <div class="moblieItem2"></div>
      </div>
      <div v-if="step == 2">
        <el-form
          ref="formvalue1"
          @submit.native.prevent
          :model="formvalue1"
          :rules="Rules"
        >
          <div class="step">
            <div class="step1">
              <a class="act">{{
                lang_data == 1 ? "验证身份" : "Authenticate"
              }}</a
              ><a>{{
                lang_data == 1 ? "重置密码" : "To reset your password"
              }}</a>
            </div>
            <div class="step2">
              <a class="act">&nbsp;</a><a class="act">&nbsp;</a>
            </div>
          </div>

          <el-form-item label="" prop="password" class="mt20">
            <el-input
              v-model="formvalue1.password"
              size="small"
              type="password"
              :placeholder="
                lang_data == 1 ? '设置新密码' : 'Setting a New Password'
              "
            />
          </el-form-item>

          <el-form-item label="" prop="password1">
            <el-input
              v-model="formvalue1.password1"
              size="small"
              type="password"
              :placeholder="
                lang_data == 1
                  ? '再次输入新密码'
                  : 'Enter the new password again'
              "
            />
          </el-form-item>
          <el-form-item label="">
            <el-button
              type="primary"
              @click="changePwd('formvalue1')"
              style="width: 100%"
              round
              size="small"
            >
              {{ lang_data == 1 ? "确认" : "confirm" }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="step == 3">
        <div class="moblieComplete">
          <el-result icon="success" title="重置密码成功" subTitle="">
            <template slot="extra">
              <el-button type="primary" size="medium" @click="$router.push('/')"
                >返回登录页面</el-button
              >
            </template>
          </el-result>
        </div>
      </div>
    </div>
    <div class="copyright">
      ©2021 欣美康 粤ICP备2021060080号 All right reserved.
    </div>
  </div>
</template>
<script>
export default {
  name: "moblie",
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      step: 1,
      wait: 0,
      radio: 1,
      codeName: localStorage.lang_data == 1 ? "获取验证码" : "Access to verify",
      formvalue: {
        pwdCode: "",
      },
      formvalue1: {
        password: "",
        password1: "",
      },
      codeIndex: 60,
      Rules2: {
        phone: [
          {
            required: true,
            message:
              localStorage.lang_data == 1
                ? "请输入手机号码"
                : "Please enter your mobile number",
            trigger: "blur",
          },
        ],
        pwdCode: [
          {
            required: true,
            message:
              localStorage.lang_data == 1
                ? "请输入验证码"
                : "Please enter the verification code",
            trigger: "blur",
          },
        ],
      },
      Rules: {
        password: [
          {
            required: true,
            message:
              localStorage.lang_data == 1
                ? "请输入密码"
                : "Please enter your password",
            trigger: "blur",
          },
          {
            min: 6,
            message:
              localStorage.lang_data == 1
                ? "请输入至少6个字符"
                : "Please enter at least 6 characters",
            trigger: "blur",
          },
        ],
        password1: [
          {
            required: true,
            message:
              localStorage.lang_data == 1
                ? "请再次输入密码"
                : "Please enter your password again",
            trigger: "blur",
          },
          {
            min: 6,
            message:
              localStorage.lang_data == 1
                ? "请输入至少6个字符"
                : "Please enter at least 6 characters",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: {
    phone: {
      type: String,
      default: "",
    },
  },
  methods: {
    getPwdCode() {
      //旧号码获取验证码
      if (this.codeIndex == 60) {
        this.codeIndex--;
        this.$axios
          .get("/getpwdcode", {
            params: { mobile: this.formvalue.phone, account_type: this.radio },
          })
          .then((res) => {
            if (res.code == 1) {
              this.$message.success(res.msg);
              let time = setInterval(() => {
                this.codeName = "重新发送（" + this.codeIndex + "）";
                this.codeIndex--;
                if (this.codeIndex == 0) {
                  clearInterval(time);
                  this.codeName = "重新发送";
                  this.codeIndex = 60;
                }
              }, 1000);
            } else {
              this.$message.error(res.msg);
              this.codeIndex = 60;
            }
          });
      }
    },
    checkChangePwdCode(info) {
      //修改密码验证码确认
      this.$refs[info].validate((valid) => {
        if (valid) {
          var params = {
            smscode: this.formvalue.pwdCode,
            mobile: this.formvalue.phone,
            account_type: this.radio,
          };
          this.$axios.post("getcheckpwd", params).then((res) => {
            if (res.code != 1) {
              this.$Message.error(res.msg);
              return;
            }
            this.step++;
          });
        }
      });
    },
    changePwd(info) {
      //修改密码
      this.$refs[info].validate((valid) => {
        if (valid) {
          if (this.formvalue1.password != this.formvalue1.password1) {
            this.$message.warning(
              this.lang_data == 1
                ? "两次密码不一致"
                : "The two passwords are different"
            );
            return;
          }
          var params = {
            mobile: this.formvalue.phone,
            smscode: this.formvalue.pwdCode,
            password: this.formvalue1.password,
            password1: this.formvalue1.password1,
            account_type: this.radio,
          };
          this.$axios.post("getpwdsave", params).then((res) => {
            if (res.code != 1) {
              this.$message.error(res.msg);
              return;
            }
            this.step++;
            //		                this.$message.success('提交成功');
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.forgetWin {
  width: 700px;
  margin: 0 auto;
  background: #fff;
  #getCode:disabled {
    background: #eeeded;
    border-color: #eeeded;
    color: #b1b1b1;
  }
  .logo {
    img {
      width: 250px;
    }
  }
  .header {
    line-height: 70px;
    text-align: center;
    font-size: 22px;
    color: #ffffff;
    height: 70px;
    background: #0d0c1f;
  }
  .mcontent {
    margin: 0 168px;

    height: 460px;
    .til {
      height: 100px;
      line-height: 100px;
      text-align: center;
      font-size: 18px;
    }
  }
}

.step {
  overflow: hidden;
  a {
    cursor: default;
    &:hover {
      cursor: default;
    }
  }
  width: 100%;
  .step1 {
    width: 100%;
    height: 30px;
    line-height: 30px;
    float: left;
    font-size: 13px;
    color: #333333;
    a {
      width: 50%;
      float: left;
      &:hover {
        text-decoration: none;
      }
      &:last-child {
        text-align: right;
      }
    }
  }
  .step2 {
    width: calc(100% - 36px);
    height: 3px;
    background: #dbdbdb;
    float: left;
    position: relative;
    margin: 10px 18px 20px 18px;
    a {
      width: 50%;
      height: 3px;
      float: left;
      &:hover {
        text-decoration: none;
      }
      &:first-child {
        &:before {
          width: 10px;
          height: 10px;
          background: #999;
          content: "";
          top: -7px;
          left: -18px;
          display: inline-block;
          position: absolute;
          border-radius: 50%;
          border: 5px solid #e0e0e0;
        }
      }
      &:last-child {
        &:before {
          width: 10px;
          height: 10px;
          background: #999;
          content: "";
          top: -7px;
          right: -18px;
          display: inline-block;
          position: absolute;
          border-radius: 50%;
          border: 5px solid #e0e0e0;
        }
      }
      &.act {
        background: #009a83;
        &:before {
          background: #009a83;
          border-color: #c6c9f4;
        }
      }
    }
  }
}
.moblieComplete {
  width: 100%;
  height: 200px;
  float: left;
  background: url(/static/img/modifySuccess.png) no-repeat center 0;
  text-align: center;
  p {
    font-size: 14px;
    color: #888;
  }
  p:first-child {
    font-size: 18px;
    color: #333;
    line-height: 40px;
    margin-top: 90px;
    padding-left: 10px;
  }
  p:last-child {
    label {
      color: #333;
    }
  }
}
.back {
  color: red;
  text-align: center;
  line-height: 60px;
  font-size: 14px;
  cursor: pointer;
}
.copyright {
  position: absolute;
  top: 710px;
  text-align: center;
  width: 700px;
}
._full {
  background: #f7f8f9;
  height: 100%;
}
</style>
<style lang='scss'>
</style>